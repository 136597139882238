

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 530px;
  padding: 30px 50px;
}

.controlButtons {
  display: flex;
  justify-content: space-between;
  button {
    min-height: 42px;
  }
}



.selects {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  gap: 15px;
}

.simpleBar {
  height: 45vh;
}

.selectRoom {

}

.selectsGroup {
  display: flex;
  justify-content: space-between;
}

.select {
  width: 48%;
}

.table {
  font-size: 12px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  //margin-bottom: 20px;
  margin: 0;
}

.tab {
  background: var(--input-bg);
  border: var(--button-border);


  text-align: center;
  text-decoration: none;
  user-select: none;

  width: 200px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 44px;
  padding: 6px 0;
  color: var(--text-color);
  opacity: 0.5;
}

.activeTab {
  opacity: 1;
  //outline: 2px solid rgba(125, 67, 201, 0.2);
  //outline: 2px solid white;
}

.checkboxContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.checkboxPercent {
  font-size: 13px;
  font-weight: 600;
  color: var(--accent-color);
}

.input {
  border-radius: 10px;
  padding: 12px;
  font-size: 11px;
  height: 16px;
  width: 100%;
  //width: 150px;
}

.rangeInput {
  border-radius: 10px;
  padding: 12px;
  font-size: 11px;
  height: 16px;
  width: 90px;
}

.interactiveField {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.selectField {}

.configList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 5px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 46% 54%;
  //gap: 10px;
}

.itemContainerParent {
  display: grid;
  grid-template-columns: 45% 45% 10%;
  gap: 0.2rem;
}


.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.itemTitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color)
}

.parentTitle {
  font-weight: 700;
}

.itemDescr {
  font-size: 9px;
  text-align: left;
  max-width: 80%;
}

.disabled {
  opacity: 0.5;
}
