@import "../../node_modules/react-datepicker/dist/react-datepicker.css";

@font-face {
    font-family: Montserrat;
    font-weight: 400;
    src: url(../fonts/Montserrat-Regular.ttf)
}

@font-face {
    font-family: Montserrat;
    font-weight: 500;
    src: url(../fonts/Montserrat-Medium.ttf)
}

@font-face {
    font-family: Montserrat;
    font-weight: 600;
    src: url(../fonts/Montserrat-SemiBold.ttf) /* Убедитесь, что имя файла правильное */
}

@font-face {
    font-family: Montserrat;
    font-weight: 700;
    src: url(../fonts/Montserrat-Bold.ttf)
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Montserrat
}

:root {
    --toastify-color-dark: #282c35 !important;
    --bg-primary-color: #eaebf0;
    --accent-color: #7d43c9;
    --text-color: #000000;
    --main-button-bg: linear-gradient(to right, #7A37B4, #7B47D4);
    --main-button-text-color: #ffffff;
    --button-bg: #F6F6F9;
    --button-bg-gray: #EAECF0;
    --button-shadow: none;
    --button-border: 1px solid #fafafb;
    --content-bg: #f5f5f9;
    --input-bg: #EAEBF0;
    --placeholder-color: rgba(0,0,0, 0.4);
    --select-bg: #f5f5f8;
    --input-border: 1px solid #EDEEF0;
    --input-border-error: 1px dotted red;
    --input-shadow: none;
    --hr-line-border: 1px solid #E5E5E5;
    --modal-border: 1px solid rgba(255, 255, 255, 0.4);
    --checkbox-bg: #d9d9d9;
    --checkbox-circle-color: #fff;
    --eye-bg: #dedfe4;
    --menu-bg-color: #e1e2e6;
    --text-size-color: #666666;
    --app-item-bg-color: #f1f2f5;
    --dropdown-bg: #FAFAFA;
    --calendar-bg: #EAECF0;
    --note-input-bg: #dcdbe0;

    --bg-primary-color-dark: #282c35;
    --accent-color-dark: #8741ce;
    --text-color-dark: #FFFFFFCC;
    --main-button-text-color-dark: #ffffff;
    --button-bg-dark: #1e2128;
    --button-border-dark: 1px solid #3B3C43;
    --content-bg-dark: #232630;
    --content-border-dark: 2px solid #3b3c43;
    --input-border-dark: 2px solid rgba(91, 91, 91, 0.4);
    --input-shadow-dark: 2.5px 2.5px 9.8px 0px rgba(0, 0, 0, 1) inset, 0px -2px 13px 0px rgba(10, 10, 11, 1) inset;
    --input-bg-dark: #1a1c23;
    --placeholder-color-dark: rgba(255,255, 255, 0.4);
    --select-bg-dark: #2e323e;
    --hr-line-border-dark: 1px solid #FFFFFF1A;
    --modal-border-dark: 1px solid rgba(91, 91, 91, 0.4);
    --checkbox-bg-dark: #1e2127;
    --checkbox-circle-color-dark: #707176;
    --eye-bg-dark: #20242b;
    --menu-bg-color-dark: #1e2127;
    --text-size-color-dark: #e0e0e0;
    --app-item-bg-color-dark: #232630;
    --dropdown-bg-dark: #2F333C;
    --note-input-bg-dark: #393c45;
}

a {
    text-decoration: none !important;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
}

.App {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    margin: 0 auto;
    max-height: 100vh;
    max-width: 1980px;
    min-width: 1440px;
    padding: 0 49px
}

ul, li {
    padding: 0;
}

li {
    list-style: none none;
}

body {
    background: var(--bg-primary-color);
}

.indicator-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.indicator {
    content: '';
    display: flex;
    align-self: center;
    margin-right: 4px;
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
}

.disabled-indicator {
    background-color: gray;
}

.header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 54px 0 31px;
    width: 100%
}

.logo {
    background: url(../shared/images/logoEyeBlack.svg) center center/contain no-repeat;
    height: 72px;
    min-height: 72px;
    min-width: 120px;
    width: 120px
}

.headersMenu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: 40px;
    width: 450px;
}

.headersMenu a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: var(--button-bg);
    border: var(--button-border);
    border-radius: 44px;
    color: rgba(0,0,0, 0.4);
    display: block;
    font-size: 11px;
    font-weight: 700;
    height: 30px;
    letter-spacing: -.11px;
    line-height: 28px;
    margin-right: 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: 138px;
    will-change: transform;
    will-change: color;
    position: relative;
}

.headersMenu a.active {
    color: var(--text-color);
    -webkit-transform: none !important;
    transform: none !important;
    //outline: 2px solid rgba(125, 67, 201, 0.2);
}

.headersMenu a:hover {
    -webkit-transform: scale(1.06);
    transform: scale(1.06)
}

.extra-menu {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    gap: 10px;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.header .balance {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 13px
}

.header .balance span {
    color: var(--accent-color);
    display: block;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -.24px;
    line-height: 29px;
    text-transform: uppercase
}

/*.header .balance::before {*/
/*    color: #000;*/
/*    content: 'Fuel balance';*/
/*    display: block;*/
/*    font-size: 11px;*/
/*    font-weight: 400;*/
/*    letter-spacing: -.11px;*/
/*    line-height: normal;*/
/*    text-shadow: 5px 5px 10px #A6ABBD, -5px -5px 10px #FAFBFF;*/
/*    text-transform: uppercase*/
/*}*/

.balanceSpan {
    color: rgb(35, 36, 40);
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -.11px;
    line-height: normal;
    text-transform: uppercase
}



.content-table {
    display: table;
    width: 100%;
    background: var(--content-bg);
}

body.dark .content-table {
    background: var(--content-bg-dark);
}

.table-th {
    background: var(--content-bg);
    color: var(--text-color);
    display: table-header-group;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100
}

.table-tr {
    display: table-row
}

.table-th > div,
.table-tr > div {
    display: table-cell;
    white-space: nowrap
}

.table-th > div {
    border-bottom: 1px solid #ccc;
    height: 74px;
    padding: 10px 3px 10px;
    vertical-align: middle
}

.table-tr > div {
    border-bottom: 1px solid #E5E5E5;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    line-height: 20px;
    padding: 0 3px;
    vertical-align: middle
}

.table-tr:last-child > div {
    border-bottom: none
}

.filter-check {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 11px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    letter-spacing: -.11px;
    margin-right: 22px;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.select-item,
.filter-check input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 3px solid #D9D9D9;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    margin: 5px;
    outline: none;
    -webkit-transition: border .2s;
    transition: border .2s;
    vertical-align: middle;
    width: 16px
}

.select-item:hover,
.filter-check:hover input {
    border: 3px solid #D0D0D0
}

.filter-check input {
    height: 20px;
    margin-right: 8px;
    width: 20px
}

.select-item:checked,
.filter-check input:checked {
    border: 3px solid var(--accent-color)
}

span.dev-info {
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    padding: 3px;
    -webkit-transition: color .2s;
    transition: color .2s
}

span.dev-info:hover {
    color: var(--accent-color)
}

.modal .on-off,
.on-off {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-transition: .3s;
    appearance: none;
    background: #d9d9d9;
    border: none;
    border-radius: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 22px;
    margin: 0;
    outline: 0;
    position: relative;
    transition: .3s;
    vertical-align: top;
    width: 37px
}

.modal .on-off:checked,
.on-off:checked {
    background-color: var(--accent-color) !important
}

.modal .on-off::after,
.on-off::after {
    -webkit-transform: translateX(0);
    -webkit-transition: .3s;
    background-color: #fff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 16px;
    left: 3px;
    position: absolute;
    top: 3px;
    transform: translateX(0);
    transition: .3s;
    width: 16px
}

.modal .on-off:checked::after,
.on-off:checked::after {
    -webkit-transform: translateX(calc(100% - 1px));
    transform: translateX(calc(100% - 1px))
}

.modal-overlay {
    -webkit-backdrop-filter: blur(0px);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transition: .3s;
    transition: .3s;
    width: 100%;
    will-change: backdrop-filter;
}

.ReactModal__Body--open .modal-overlay {
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    background-color: rgba(0, 0, 0, 0.5)
}

.ReactModal__Overlay--before-close.modal-overlay {
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0)
}

.modal {
    background: var(--content-bg);
    border-radius: 25px;
    height: auto;
    max-width: 756px;
    position: relative;
    width: auto;
    will-change: transform;
    will-change: opacity
}

.modal > div .p-btn {
    color: #000;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.11px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    text-transform: uppercase;
    -webkit-transition: color .2s, -webkit-transform .2s;
    transition: color .2s, -webkit-transform .2s;
    transition: transform .2s, color .2s;
    transition: transform .2s, color .2s, -webkit-transform .2s;
    will-change: transform;
}

.modal > div .p-btn:hover {
    -webkit-transform: scale(1.07);
    transform: scale(1.07)
}

.modal > div .p-btn.extra {
    background: var(--accent-color);
    color: #fff;
    margin-top: 30px
}

.modal-inner-mini {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 280px;
    width: 354px
}

.modal-inner-mini-q {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 280px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 20px;
    width: 354px
}

.modal-inner-mini-q .p-btn {
    height: 25px;
    width: 152px
}

.modal-icon-q svg {
    height: 42px;
    margin: 20px;
    width: 42px
}

.modal-inner-mini .modal-btns {
    margin-top: 10px
}

.modal-inner-mini-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.modal-inner-mini .p-btn {
    height: 25px;
    width: 152px
}

.modal-title {
    color: var(--text-color);
    font-size: 30px;
    font-weight: 500;
    padding: 19px;
    text-align: center;
    text-transform: uppercase
}

.modal-title-q {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.15px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase
}

.modal-inner-history .modal-title {
    padding-right: 50px
}

.modal-inner-history {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 498px;
    padding: 0 0 50px 50px;
    position: relative;
    width: 402px
}

.modal-history-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding-right: 50px;
    position: relative;
    width: 100%
}

.modal-history-content > .simplebar-track.simplebar-vertical {
    margin-right: 10px;
    margin-top: 38px
}

.modal-history-content .table-th > div {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: auto;
    line-height: normal;
    padding: 10px 0
}

.modal-history-content .table-tr > div {
    height: 26px
}

.modal-history-content .simplebar-content {
    height: auto;
    position: relative;
}

.modal-history-content .table-th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0
}

.modal-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 10px
}

span.partner-options {
    color: var(--text-color);
    cursor: pointer;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    -webkit-transition: .2s;
    transition: .2s
}

span.partner-options:hover {
    color: var(--accent-color) !important
}

.App {
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    will-change: transform
}

body.ReactModal__Body--open .App {
    -webkit-transform: scale(0.9);
    transform: scale(0.9)
}

.p-btn {
    -webkit-backface-visibility: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    backface-visibility: hidden;
    background: var(--button-bg);
    border: var(--button-border);
    border-radius: 15px;
    //-webkit-box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    //box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 32px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: transform
}

.grayBg {
    background: var(--button-bg-gray);
};

body.dark .grayBg {
    background: var(--button-bg-dark);
}

.p-btn svg {
    will-change: fill
}

.item-options .p-btn {
    color: var(--text-color);
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -.11px;
    text-transform: uppercase
}

.dropdown-menu .p-btn {
    margin-bottom: 15px;
    text-align: center;
    -webkit-transition: color .3s, -webkit-transform .2s;
    transition: color .3s, -webkit-transform .2s;
    transition: color .3s, transform .2s;
    transition: color .3s, transform .2s, -webkit-transform .2s;
    width: 74px !important
}

.dropdown-menu .p-btn:hover {
    -webkit-transform: scale(1.07) !important;
    transform: scale(1.07) !important
}

.user .dropdown-menu .p-btn svg {
    height: 14px;
    margin-left: 5px;
    width: 14px
}

.user .dropdown-menu .p-btn {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -.11px;
    line-height: normal;
    text-align: center;
    text-transform: capitalize;
    height: auto !important;
    padding: 8px;
    width: 112px !important;
}

.dropdown-menu .p-btn:hover {
    color: var(--accent-color);
}

.dropdown-menu .p-btn:hover svg {
    fill: var(--accent-color);
}

.dropdown-menu .p-btn:last-child {
    margin-bottom: 0
}

.extra-menu .p-btn {
    width: 73px
}

.extra-menu .p-btn:hover {
    -webkit-transform: scale(1.07);
    transform: scale(1.07)
}

.item-options {
    position: relative
}

.item-options .p-btn svg {
    height: 28px;
    width: 28px
}

.p-btn.settings svg {
    height: 22px;
    width: 22px
}

.manage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.manage .p-btn {
    margin-left: 6px;
    width: 30px
}

.manage .p-btn:hover {
    -webkit-transform: scale(1.12);
    transform: scale(1.12)
}

.header svg {
    -webkit-transition: fill .3s;
    transition: fill .3s
}

.header .p-btn:hover svg {
    fill: var(--accent-color);
}

.item-options.active > .p-btn {
    -webkit-transform: none !important;
    transform: none !important
}

.user.active > .p-btn svg,
.item-options.active svg {
    fill: var(--accent-color);
}

.user.active > .p-btn {
    -webkit-transform: none !important;
    transform: none !important
}

.manage .p-btn::after {
    content: '';
    display: block
}

.header .user {
    position: relative
}

.p-btn.energy svg {
    height: 18px;
    width: 18px
}

.p-btn.history svg,
.user .p-btn svg {
    height: 18px;
    width: 18px
}

#logout {
    background: var(--accent-color);
    color: #fff;
    text-transform: uppercase
}

.item-options-plug {
    width: 73px
}

.dropdown-menu {
    background: var(--dropdown-bg);
    border: var(--button-border);
    border-radius: 10px;
    //-webkit-box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    //box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    cursor: default;
    position: absolute;
    right: 0;
    top: 38px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100px;
    z-index: 120
}

.item-options .dropdown-menu {
    padding: 18px;
    width: 112px
}

.user .dropdown-menu {
    padding: 8px;
    width: auto
}

.dropdown-menu label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0 5px
}

.dropdown-menu label span {
    color: #101010;
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.11px;
    text-align: left;
    text-transform: uppercase
}

button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

button.ver-update {
    background: var(--accent-color);
    border: none;
    border-radius: 8px;
    color: #FFF;
    cursor: pointer;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.11px;
    line-height: normal;
    outline: none;
    outline: 8px solid rgba(0, 0, 0, 0);
    padding: 7px 6px;
    text-transform: capitalize;
    -webkit-transition: outline .2s;
    transition: outline .2s;
    will-change: outline
}

button.ver-update:hover {
    outline: 4px solid rgba(125, 67, 201, 0.13)
}

button.updating {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    outline: none;
    width: 54px
}

button.updating::after {
    -webkit-animation: updating-animation 2s infinite linear;
    animation: updating-animation 2s infinite linear;
    background: url(../shared/images/updating.svg) center center/contain no-repeat;
    content: '';
    display: block;
    height: 20px;
    width: 20px
}

.loading {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    outline: none;
    width: 54px
}

.loading::after {
    -webkit-animation: updating-animation 2s infinite linear;
    animation: updating-animation 2s infinite linear;
    background: url(../shared/images/updating.svg) center center/contain no-repeat;
    content: '';
    display: block;
    height: 20px;
    width: 20px
}

@-webkit-keyframes updating-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes updating-animation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.select-wrap {
    height: 30px;
    margin: 0;
    overflow: visible;
    position: relative;
    //z-index: 100
}

.select-wrap * {
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.select-dropdown {
    align-items: flex-start;
    background: var(--select-bg);
    border: var(--input-border);
    text-decoration: none;
    user-select: none;
    border-radius: 8px;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    height: 30px;
    justify-content: center;
    overflow: hidden;
    padding: 0 6px;
    transition: height .3s;
    user-select: none;
    text-align: left;
}

.graySelect {
    background: var(--input-bg);
}

.selected-hint {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 50px;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    height: 20px;
    padding: 0;
    border-radius: 10px;
    color: var(--accent-color);
    font-size: 13px;
    font-weight: 600;
}

.optionWithIcon {
    padding-left: 22px;
}

.optionIcon {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 5px;
    display: block;
    left: -14px;
    top: 50%;
    transform: translate(50%, -50%);
}

.totalPrice {
    background-color: var(--accent-color);
    color: #ffffff;
    right: 38px;
    padding: 0 20px;
}

.interactiveInput {
    background: var(--input-bg);
    border: var(--input-border);
    color: var(--text-color)
}

.interactiveInput::placeholder {
    color: var(--text-color)
}

body.dark .interactiveInput {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    color: var(--text-color-dark)
}

body.dark .interactiveInput::placeholder {
    color: var(--text-color-dark)
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input {
    outline: none;
}

input::placeholder {
    color: var(--placeholder-color) !important;
}

body.dark input::placeholder {
    color: var(--placeholder-color-dark) !important;
}

.inputTitle {
    color: var(--text-color)
}

body.dark .inputTitle {
    color: var(--text-color-dark)
}

.tabControl {
    background: var(--menu-bg-color);
    color: var(--text-color);
    border: var(--button-border);
    cursor: pointer
}

body.dark .tabControl {
    background: var(--menu-bg-color-dark);
    color: var(--text-color-dark);
    border: var(--button-border-dark)
}

.resetButton {
    background: var(--input-bg);
    color: var(--text-color);
    border: none;
    text-align: center;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    padding: 6px 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    border-radius: 19px;
}

body.dark .resetButton {
    background: var(--button-bg-dark);
    color: var(--text-color-dark);
}

.saveButton {
    background: var(--accent-color);
    color: white;
    border: none;
    text-align: center;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    padding: 6px 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    border-radius: 19px;
}

.dropdown-selected {
    cursor: pointer;
    display: block;
    height: 30px;
    line-height: 30px;
    margin-right: 24px;
    margin-left: 6px;
    //padding: 0 0 0 6px;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    //border: 1px solid #EDEEF0;

}

.withIcon {
    padding-left: 22px;
}

.dropdown-icon {
    position: absolute;
    height: 26px;
    width: 26px;
    border-radius: 5px;
    display: block;
    left: -8px;
    top: 50%;
    transform: translate(50%, -50%);
}

.dropdown-selected input {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;

}

.dropdown-selected input:focus {
    cursor: text
}

.dropdown-selected svg {
    background: rgba(255, 255, 255, .0);
    border-radius: 50%;
    height: 16px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    transition: fill .3s, transform .3s;
    width: 16px;
    fill: var(--text-color);
}

.dropdown-selected:hover svg {
    fill: #7A37B4;
}

.dropdown-search {
    color: var(--text-color);
    font-weight: 500;
}

.select-wrap.active svg {
    fill: #7A37B4;
    transform: translateY(-50%) rotate(180deg)
}

.dropdown-options {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    //z-index: 100;
}

.dropdown-options li {
    -webkit-transition: .11s;
    border-bottom: var(--hr-line-border);
    cursor: pointer;
    display: block;
    text-align: left;
    height: 30px;
    line-height: 29px;
    list-style: none;
    padding: 0 5px 0 10px;
    position: relative;
    -webkit-transition: background .21s;
    transition: background .21s;
    white-space: nowrap
}

.dropdown-options li:hover {
    background: rgba(0, 0, 0, .033);
    border-radius: 4px
}

.dropdown-options li:first-child {
    border-top: var(--hr-line-border)
}

.dropdown-options li:last-child {
    border-bottom: none
}

.select-wrap.active svg {
    fill: #7A37B4;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg)
}

.select-wrap.active {
    z-index: 100
}

.disable-select {
    pointer-events: none;
}

.select-hint {
    color: var(--accent-color)
}

.dropdownOptionsItem {
    -webkit-transition: .11s;
    border-bottom: var(--hr-line-border);
    cursor: pointer;
    display: block;
    text-align: left;
    height: 30px;
    line-height: 29px;
    list-style: none;
    padding: 0 5px 0 5px;
    position: relative;
    -webkit-transition: background .21s;
    transition: background .21s;
    white-space: nowrap
}

.dropdown-option-label {
    display: flex;
    justify-content: space-between;
}

.header .select-wrap {
    width: 217px
}

.login-page {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.agent-code {
    background: var(--main-button-bg);
    border-radius: 25px;
    border: none;
    color: #FFF;
    cursor: pointer;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    height: 40px;
    line-height: normal;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 240px
}

body.dark .agent-code {
    color: var(--text-color-dark);
    -webkit-filter: none;
    filter: none
}

.table-filters {
    background: var(--content-bg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    padding-top: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 200
}

.more-btn {
    padding-left: 20px;
    padding-right: 20px;
}

.more {
    display: flex;
    width: 100%;
    justify-content: center;
}

.table-filters .select-wrap {
    width: auto
}

.react-datepicker__triangle {
    display: none
}

.react-datepicker {
    -webkit-user-select: none;
    background: var(--calendar-bg);
    border: var(--button-border);
    border-radius: 20px;
    //-webkit-box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    //box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    cursor: default;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.react-datepicker__header--custom {
    background: none;
    border-bottom: 0;
    position: static;
    z-index: 900
}

.react-datepicker__day {
    border-radius: 50% !important;
    color: var(--text-color);
    font-size: 9px;
    font-weight: 700;
    height: 15px;
    letter-spacing: -.08px;
    line-height: normal;
    line-height: 15px;
    margin: 3px 5px 3px 5px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: background .2s;
    transition: background .2s;
    width: 15px
}

.react-datepicker__day:hover {
    background: var(--accent-color);
    color: white;
}

.react-datepicker__day-name {
    color: rgba(0, 0, 0, 0.25);
    font-family: Montserrat;
    font-size: 7px;
    font-weight: 700;
    height: 13px;
    letter-spacing: -.06px;
    line-height: 13px;
    margin: 2px 4px 2px 4px;
    text-align: center;
    text-transform: uppercase;
    width: 17px
}

.custom-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 6px 10px 0 12px;
    z-index: 900
}

.custom-header-date {
    color: var(--accent-color);
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -.1px;
    text-align: left
}

.react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none
}

.react-datepicker__month {
    margin-top: 0
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
    background: var(--accent-color) !important;
    color: #fff !important
}

.react-datepicker__day--today {
    background: none !important;
    color: var(--accent-color) !important
}

.year-dropdown-wrap {
    height: calc(100% - 40px);
    left: 0;
    opacity: 1;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 600
}

.year-dropdown {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: auto;
    padding: 0 6px;
    z-index: 600
}

.year-dropdown > div {
    -ms-flex-item-align: start;
    align-self: start;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    display: inline-block;
    float: left;
    font-size: 9px;
    font-weight: 700;
    height: 16px;
    line-height: 16px;
    -webkit-transition: background .2s;
    transition: background .2s
}

.year-dropdown > div:hover {
    background: rgba(0, 0, 0, 0.07)
}

.year-selection .react-datepicker__day-names,
.year-selection .react-datepicker__month {
    opacity: 0
}

.year-selection .react-datepicker-btns {
    display: none
}

.year-btn {
    border-radius: 4px;
    cursor: pointer;
    padding: 1px 2px;
    -webkit-transition: background .2s;
    transition: background .2s
}

.year-btn:hover {
    background: rgba(0, 0, 0, 0.05);
}

.active-year {
    background: var(--accent-color) !important;
    color: #fff !important
}

.react-datepicker__day-names {
    padding: 0 7px;
    width: 100%
}

.react-datepicker__close-icon::after {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    -webkit-transition: background .2s;
    transition: background .2s
}

.react-datepicker__close-icon:hover::after {
    background: rgba(0, 0, 0, 0.25)
}

.react-datepicker__input-container input {
    background: var(--input-bg);
    border: var(--input-border);
    border-radius: 8px;
    //-webkit-box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    //box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 500;
    height: 25px;
    letter-spacing: -.11px;
    line-height: 25px;
    outline: none;
    padding: 0 10px;
    width: 100px
}

.custom-header-date {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.react-datepicker-month-text,
.react-datepicker-year-text {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.react-datepicker-year-text {
    border-radius: 4px;
    cursor: pointer;
    margin-left: 2px;
    padding: 2px 3px;
    -webkit-transition: background .2s;
    transition: background .2s
}

.react-datepicker-year-text:hover {
    background: rgba(0, 0, 0, 0.04)
}

.react-datepicker-year-text svg {
    display: block;
    fill: var(--accent-color);
    height: 7px;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: 7px
}

.year-selection .react-datepicker-year-text svg {
    -webkit-transform: rotate(90deg) translateY(-1px);
    transform: rotate(90deg) translateY(-1px)
}

.react-datepicker-btns {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.react-datepicker-btns div {
    border-radius: 3px;
    cursor: pointer;
    padding: 3px;
    -webkit-transition: background .2s;
    transition: background .2s
}

.react-datepicker-btns div:hover {
    background: rgba(0, 0, 0, 0.05)
}

.react-datepicker-btns div svg {
    display: block;
    fill: var(--accent-color);
    height: 8px;
    width: 8px
}
//
//.react-datepicker-btns div:first-child {
//    -webkit-transform: rotate(180deg);
//    transform: rotate(180deg)
//}

.table-filters .select-wrap,
.table-filters .select-dropdown {
    height: 25px
}

.table-filters .dropdown-selected svg {
    height: 13px;
    right: 7px;
    width: 13px
}

.table-filters .dropdown-selected {
    font-size: 11px;
    height: 25px;
    line-height: 25px
}

.table-filters .select-wrap {
    margin: 0
}

.table-filters .dropdown-options li {
    font-size: 11px;
    height: 25px;
    line-height: 24px
}

.table-filters .select-wrap:nth-child(1) {
    width: 177px
}

.table-filters .select-wrap:nth-child(2) {
    width: 139px
}

.table-filters .select-wrap:nth-child(3) {
    width: 139px
}

.table-filters .select-wrap:nth-child(4) {
    width: 120px
}

.table-filters-l {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.table-filters-l > div {
    margin-right: 12px !important
}

.table-filters-r {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.table-filters .p-btn {
    color: var(--text-color);
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    height: 32px;
    letter-spacing: -.11px;
    line-height: normal;
    margin-left: 14px;
    text-transform: uppercase;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    width: 123px;
    will-change: transform
}

.table-filters .p-btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.partner-history .p-btn {
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    will-change: transform
}

.partner-history .p-btn:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.partner-history .p-btn svg {
    -webkit-transition: fill .2s;
    transition: fill .2s;
    will-change: fill
}

.partner-history .p-btn:hover svg {
    fill: var(--accent-color)
}

.content.sessions .table-th {
    top: 60px
}

.content.partners .table-th {
    top: 60px
}

.dropdown-selected input {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0 0 0 4px;
    width: 100%
}

.dropdown-selected input:focus {
    cursor: text
}

.table-filters .dropdown-selected input {
    font-size: 11px
}

.editable-note-th {
    padding-left: 5px !important
}

.editable-note {
    width: auto
}

.editable-note span {
    border-radius: 8px;
    cursor: pointer;
    display: block;
    height: 25px;
    line-height: 25px;
    padding: 0 6px;
    -webkit-transition: background .2s;
    transition: background .2s;
    width: 100px;
    background-color: var(--note-input-bg);
}

.editable-note span:hover {
    background: rgba(0, 0, 0, 0.03)
}

.editable-note input {
    background: var(--input-bg);
    border: var(--input-border);
    border-radius: 8px;
    //-webkit-box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    //box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    color: var(--text-color);
    height: 25px;
    letter-spacing: -.11px;
    line-height: 25px;
    outline: none;
    padding: 0 6px;
    width: 100px;
}

.content.accounts .table-th {
    top: 0
}

.content > .simplebar-track {
    margin: 140px 0 35px
}

.content.accounts > .simplebar-track {
    margin: 85px 0 35px
}

.fuel-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.fuel-btn {
    border-radius: 8px;
    color: #FFF;
    cursor: pointer;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    height: 28px;
    letter-spacing: -.11px;
    line-height: 28px;
    line-height: 28px;
    outline: 8px solid rgba(0, 0, 0, 0);
    text-align: center;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: outline .2s;
    transition: outline .2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80px;
    will-change: outline
}

.fuel-btn.in-out-btn {
    background: #8C35B4;
    margin-right: 14px
}

.fuel-btn.in-out-btn:hover {
    outline: 4px solid rgba(140, 53, 180, 0.13)
}

.fuel-btn.top-up-btn {
    background: #7E43C9;
}

.fuel-btn.top-up-btn:hover {
    outline: 4px solid rgba(126, 67, 201, 0.13)
}

.partner-history {
    text-align: center;
    width: 60px
}

.partner-history .p-btn {
    margin: 0 auto;
    width: 30px
}

.partner-history svg {
    height: 17px;
    width: 17px
}

.fuel-operations {
    width: 175px
}

.ReactModal__Body--open .modal {
    -webkit-animation: modalScale .3s;
    animation: modalScale .3s
}

.ReactModal__Overlay--before-close .modal {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition: .3s;
    transition: .3s
}

.item-options {
    -webkit-animation: itemOptions .2s;
    animation: itemOptions .2s;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    will-change: transform;
    will-change: opacity;
    z-index: 800
}

@-webkit-keyframes itemOptions {
    0% {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    70% {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes itemOptions {
    0% {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    70% {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.header .dropdown-menu {
    -webkit-animation: dropdownMenu .2s;
    animation: dropdownMenu .2s;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    will-change: transform;
    will-change: opacity
}

@-webkit-keyframes dropdownMenu {
    from {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes dropdownMenu {
    from {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes modalScale {
    from {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes modalScale {
    from {
        opacity: .3;
        -webkit-transform: scale(0.82);
        transform: scale(0.82)
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.window_close {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    -webkit-transition: .2s;
    align-items: center;
    background: var(--button-bg-gray);
    border: var(--button-border);
    border-radius: 44px;
    //-webkit-box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    //box-shadow: -5px -5px 10px #FAFBFF, 5px 5px 10px rgba(166, 171, 189, .5);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    right: 16px;
    top: 16px;
    transition: .2s;
    width: 40px;
    will-change: transform;
    z-index: 999
}

.window_close svg {
    fill: var(--text-color);
    height: 10px;
    width: 10px;
    will-change: transform
}

.window_close:hover {
    -webkit-transform: scale(1.12);
    transform: scale(1.12)
}

.modal-inner-fuel {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 280px;
    padding: 0 40px 40px;
    width: 354px
}

.modal-inner-fuel .modal-subtitle {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    text-transform: uppercase
}

.modal-inner-fuel .modal-title {
    padding: 19px 19px 0
}

.modal-fuel-bl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 17px;
    width: 100%
}

.modal-fuel-bl > div:first-child {
    color: var(--text-color);
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.11px;
    line-height: 25px;
    text-transform: uppercase
}

.modal-fuel-bl > div:last-child {
    color: var(--text-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
    width: 130px
}

.modal input.st-in {
    background: var(--input-bg);
    border: var(--input-border);
    border-radius: 8px;
    //-webkit-box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    //box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
    color: var(--text-color);
    font-size: 12px;
    font-weight: 400;
    height: 25px;
    letter-spacing: -.11px;
    line-height: 25px;
    outline: none;
    padding: 0 10px
}

.modal-header-history {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 583px;
    padding: 0 0 50px 50px;
    position: relative;
    width: 606px
}

.modal-header-history .content-table {
    display: none
}

.modal-header-history .content-table.show {
    display: table
}

body.dark {
    background: var(--bg-primary-color-dark)
}

body.dark .content {
}

body.dark .table-th {
    background: var(--content-bg-dark);
    color: var(--text-color-dark);
}

body.dark .table-th > div {
    border-bottom: 1px solid #404040
}

body.dark .table-tr > div {
    border-bottom: 1px solid #272727
}

body.dark .simplebar-track .simplebar-scrollbar:before {
    background: rgba(255, 255, 255, .4)
}

body.dark .table-tr > div {
    color: var(--text-color-dark)
}

body.dark .p-btn {
    background: var(--button-bg-dark);
    border: var(--button-border-dark);
    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    //box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    color: var(--text-color-dark)
}

body.dark .p-btn svg {
    fill: #fff;
}

body.dark .table-filters {
    background: var(--content-bg-dark)
}

body.dark .react-datepicker__input-container input {
    background: var(--input-bg-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark);
}

body.dark .react-datepicker__close-icon::after {
    background: rgba(255, 255, 255, 0.2)
}

body.dark .filter-check {
    color: var(--text-color-dark)
}

body.dark .select-dropdown {
    background: var(--input-bg-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark)
}

body.dark .select-dropdown svg {
    fill: #fff
}

body.dark .dropdown-options li {
    border-bottom: var(--hr-line-border-dark);
}

body.dark .dropdown-options li:first-child {
    border-top: var(--hr-line-border-dark);
}

body.dark .dropdown-options li:hover {
    background: rgba(255, 255, 255, 0.04)
}

body.dark .dropdown-search {
    color: var(--text-color-dark)
}

//body.dark .menu a {
//    background: var(--button-bg-dark);
//    border: var(--button-border-dark);
//    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
//    //box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.50);
//
//    color: #666 !important
//}
//
//body.dark .menu a.active {
//    color: #fff !important
//}

body.dark .headersMenu a {
    background: var(--button-bg-dark);
    border: var(--button-border-dark);
    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    //box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.50);

    color: #666
}

body.dark .headersMenu a.active {
    color: #fff
}

body.dark .react-datepicker {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    //box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    color: var(--text-color-dark);
}

body.dark .react-datepicker__day-name {
    color: rgba(255, 255, 255, 0.60)
}

body.dark .react-datepicker__day {
    color: var(--text-color-dark)
}

body.dark .year-dropdown > div {
    color: var(--text-color-dark)
}

body.dark .partner-options {
    color: var(--text-color-dark)
}

body.dark .modal .on-off,
body.dark .on-off {
    background: var(--checkbox-bg-dark)
}

body.dark .dropdown-menu {
    background: var(--dropdown-bg-dark);
    border: var(--input-border-dark);
    border-width: 1px;
    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    //box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    color: var(--text-color-dark);
}

body.dark .dropdown-menu label span {
    color: #fff !important
}

body.dark .dropdown-menu #logout {
    background: var(--accent-color-dark);
    color: var(--text-color-dark)
}

body.dark .on-off:checked {
    background-color: var(--accent-color-dark)
}

body.dark .select-item:checked,
.filter-check input:checked {
    border: 3px solid var(--accent-color-dark)
}

body.dark button.ver-update {
    background: var(--accent-color-dark);
    color: var(--text-color-dark)
}

body.dark .header .balance span {
    color: var(--accent-color-dark)
}

body.dark .header .balance::before {
    color: rgba(255, 255, 255, .3);
    text-shadow: none
}

body.dark .header .balanceSpan {
    color: rgba(166, 171, 189, 0.6)
}

body.dark .extra-menu .select-dropdown {
    background: var(--button-bg-dark);
    border: var(--input-border-dark);
    border-radius: 15px;
    //-webkit-box-shadow: inset 2.5px 2.5px 5px rgba(255, 255, 255, .3);
    //box-shadow: inset 2.5px 2.5px 5px rgba(255, 255, 255, .3)
}

body.dark .on-off::after {
    background: #707176;
}

body.dark .on-off:checked::after {
    background: var(--input-bg-dark)
}

body.dark .fuel-btns .fuel-btn {
    color: var(--text-color-dark)
}

body.dark .logo {
    background: url(../shared/images/logoEyeWhite.svg) center center/contain no-repeat;
}

.window_close svg {
    fill: #000
}

.modal {
    border: none;
    outline: none;
    overflow: hidden
}

.modal-history {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 583px;
    padding: 0 0 0 60px;
    position: relative;
    width: 606px
}

.modal-history-subagent {
    width: 500px
}

.m-history-title {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    height: 80px;
    line-height: 80px;
    padding-right: 60px;
    text-align: center;
    text-transform: uppercase
}

.modal-history .react-tabs__tab-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 60px;
    width: 100%
}

.modal-history .p-btn {
    color: rgba(0, 0, 0, .5) !important;
    width: 212px
}

.modal-history .p-btn.react-tabs__tab--selected {
    color: #000 !important
}

.m-history-content {
    height: 410px;
    margin-top: 20px;
    padding-right: 60px;
    position: relative
}

.ReactModalPortal .table-tr > div {
    height: 30px
}

.modal-history .table-th > div {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    height: 35px
}

.modal-history .react-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

.react-tabs__tab-panel {
    display: none;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.react-tabs__tab-panel--selected {
    display: block
}

.modal-history .simplebar-vertical {
    right: 15px;
    top: 38px
}

body.dark .modal {
    background: var(--content-bg-dark)
}

body.dark .modal-history .p-btn {
    color: rgba(255, 255, 255, .5) !important
}

body.dark .modal-history .table-th > div {
    color: #DDD
}

body.dark .modal-history .p-btn.react-tabs__tab--selected {
    color: #fff !important
}

body.dark .m-history-title {
    color: var(--text-color-dark)
}

body.dark .window_close {
    background: var(--button-bg-dark);
    border: var(--button-border-dark);
    //-webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    //box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.50);
    color: var(--text-color-dark)
}

body.dark .window_close svg {
    fill: #fff !important
}

body.dark .editable-note span {
    background-color: var(--note-input-bg-dark);
}

body.dark .editable-note span:hover {
    background: rgba(255, 255, 255, .3)
}

body.dark .editable-note input {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark);
}

.modal {
    position: relative
}

.modal-deposit {
    padding: 0 50px 40px;
    position: relative;
    width: 427px
}

.m-deposit-title {
    color: #000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    height: 80px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase
}

.m-deposit-go {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.m-deposit-add {
    background: var(--accent-color-dark);
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 11px;
    height: 28px;
    line-height: 28px;
    margin-bottom: 15px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

.m-deposit-add.congrats {
    background: var(--accent-color);
    cursor: auto
}

.modal-deposit .p-btn {
    -ms-flex-item-align: end;
    align-self: flex-end;
    height: 25px;
    width: 110px;
    margin-top: 10px;
}

.m-deposit-input {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 30px
}

.m-deposit-inner {
    display: flex;
    flex-direction: column;
}

.m-deposit-fuel,
.m-deposit-input {
    color: #000;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.11px;
    line-height: 30px;
    text-transform: uppercase
}

.m-deposit-input input {
    margin: 0 15px 0 0;
    width: 100px
}

.m-deposit-input-text {
    white-space: nowrap;
    width: 166px
}

.m-deposit-fuel {
    line-height: 16px;
    margin: 20px 0 40px
}

.m-deposit-fuel span {
    display: block;
    float: left
}

.m-deposit-input span:first-child,
.m-deposit-fuel span:first-child {
    display: block;
    float: left;
    width: 136px
}

.m-deposit-fuel span:nth-child(2) {
    font-weight: 500
}

.m-deposit-input-gift {
    font-weight: 600
}

body.dark .modal-deposit * {
    color: var(--text-color-dark)
}

body.dark .m-deposit-input input {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark);
}

.deposit-to-pay {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase
}

.deposit-to-pay span {
    font-weight: 700
}

.m-deposit-pay .p-btn {
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 11px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    width: 109px
}

.m-deposit-pay {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 11px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.fuel-amount-number {
    margin-left: 11px;
}

.deposit-qr-t {
    padding: 12px 0
}

.address {
    margin-top: 10px;
}

.deposit-qr {
    background: rgba(0, 0, 0, .1);
    border-radius: 3px;
    height: 125px;
    width: 125px
}

.deposit-received {
    margin: 11px 0;
    text-transform: uppercase;
    padding-bottom: 12px;
}

.deposit-received span {
    display: inline-block;
    margin-left: 20px
}

.paid-check {
    cursor: pointer;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background: var(--accent-color-dark);
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    height: 28px;
    letter-spacing: -.11px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    width: 94px;
}

.modal {
    z-index: 9999
}

.modal-settg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 337px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 50px 40px;
    width: 508px
}

.modal-settg input.st-in {
    display: inline-block;
    height: 30px;
    padding-left: 30px;
    width: 100%
}

.settg-input {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-top: 80px;
    position: relative;
    width: 333px
}

.settg-input::before {
    color: #000;
    content: '@';
    display: block;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    left: 0;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 34px
}

.settg-title {
    color: #000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    height: 80px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase
}

.modal-settg .p-btn {
    -ms-flex-item-align: end;
    align-self: flex-end;
    width: 101px
}

body.dark .settg-title {
    color: #fff
}

body.dark .modal-settg input {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark)
}

body.dark .settg-input::before {
    color: #ddd
}

.modal-settings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 600px;
    padding: 30px;
    width: 756px
}

.m-settings-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: auto
}

.m-settings-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 10px
}

.m-settings-btns .p-btn {
    margin-right: 15px;
    width: 153px
}

.m-settings-title {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase
}

.m-settings-options {
    height: 100%
}

.m-settings-test {
    height: 2000px
}

.modal-settings .select-wrap.active {
    z-index: 9999
}

.settings-panel-options {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr)
}

.m-settings-panel * {
    text-transform: uppercase
}

.m-settings-panel {
    margin-bottom: 20px;
    margin-top: 40px
}

.modal-settings .select-wrap {
    margin: 0
}

.modal-settings .react-tabs__tab-list {
    display: grid;
    gap: 70px;
    grid-template-columns: repeat(2, 1fr);
    margin: 10px 0 0;
    padding: 0
}

.modal-settings .m-settings-panel .p-btn {
    color: #888 !important
}

.modal-settings .m-settings-panel .p-btn.react-tabs__tab--selected {
    color: #000 !important
}

.modal-settings .react-tabs__tab-list .p-btn:hover {
    -webkit-transform: scale(1.04);
    transform: scale(1.04)
}

.modal-settings .dropdown-selected {
    font-size: 11px;
    height: 25px;
    line-height: 25px
}

.modal-settings .dropdown-options li {
    font-size: 11px;
    height: 25px;
    line-height: 24px
}

.options-list-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 22px
}

.options-list-item:last-child {
    margin-bottom: 30px
}

.settings-list-item-lable {
    display: flex;
}

.list-item-title {
    position: relative;
    width: 220px
}

.list-item-main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 20px
}

.list-item-apply {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 160px
}

.list-item-title label {
    cursor: pointer;
    position: relative
}

.list-item-apply span {
    color: var(--text-color);
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -.11px;
    margin-right: 5px;
    text-transform: uppercase
}

.list-item-apply label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.list-item-name {
    display: inline-block;
    margin-left: 5px
}

.list-item-name span {
    display: block
}

.list-item-name span:nth-child(1) {
    color: var(--text-color);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -.11px;
    text-transform: uppercase
}

.list-item-name span:nth-child(2) {
    color: var(--text-color);
    font-size: 9px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: -.08px
}

.modal input.lock {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline-block;
    height: 100%;
    margin-right: 13px;
    position: relative;
    width: 0;
}

input.lock::before {
    background: url(../shared/images/lock-locked.svg) center center/contain no-repeat;
    content: '';
    display: block;
    height: 13px;
    width: 13px
}

input.lock:checked::before {
    background: url(../shared/images/lock-unlocked-light.svg) center center/contain no-repeat
}

.modal-settings .dropdown-selected svg {
    height: 13px;
    width: 13px
}

.modal-settings .select-wrap,
.modal-settings .select-dropdown,
.modal-settings .dropdown-selected {
    height: 25px
}

.modal-settings .dropdown-selected input {
    font-size: 11px
}

.list-item-main input[type="text"] {
    font-size: 11px;
    width: 100%
}

.list-item-main label {
    color: var(--text-color);
    cursor: pointer;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -.11px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase
}

.list-item-main label span {
    line-height: 22px
}

.list-item-main label input.on-off {
    margin: 0 18px
}

body.dark .list-item-main input[type="text"] {
    background: var(--input-bg-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark)
}

body.dark .m-settings-title,
body.dark .modal-settings label,
body.dark .modal-settings label span {
    color: var(--text-color-dark)
}

body.dark .modal-settings .m-settings-panel .p-btn {
    color: #777 !important
}

body.dark .modal-settings .m-settings-panel .p-btn.react-tabs__tab--selected {
    color: #ddd !important
}

body.dark input.st-in,
body.dark .select-dropdown,
body.dark .react-datepicker__input-container input {
    border: 1px solid rgba(91, 91, 91, 0.40) !important
}

body.dark .m-settings-test {
    color: var(--text-color-dark)
}

.m-partner-history {
    height: 498px;
    padding: 0 0 0 50px;
    width: 402px
}

.m-partner-history .m-history-content {
    height: 380px
}

.m-partner-history .table-th > div {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 500;
    height: 45px
}

.m-partner-history .m-history-content {
    margin-top: 0
}

.m-partner-history .table-tr > div:nth-child(2) {
    text-align: right
}

.m-partner-history .table-th > div:nth-child(2) {
    text-align: center
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    25% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px)
    }

    50% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px)
    }

    75% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    25% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px)
    }

    50% {
        -webkit-transform: translateX(15px);
        transform: translateX(15px)
    }

    75% {
        -webkit-transform: translateX(-15px);
        transform: translateX(-15px)
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.modal.sorry {
    -webkit-animation: shake .5s;
    animation: shake .5s
}

.modal-fuel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 280px;
    padding: 20px 40px 40px;
    width: 354px
}

.modal-fuel .m-fuel-title {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase
}

.modal-fuel .m-fuel-partner {
    color: var(--text-color);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase
}

.modal-fuel .my-balance,
.modal-fuel .partner-balance {
    color: var(--text-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.11px;
    line-height: normal;
    margin-top: 15px;
    text-transform: uppercase;
    width: 100%
}

.modal-fuel .my-balance span:nth-child(2),
.modal-fuel .partner-balance span:nth-child(2) {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 15px
}

.modal-fuel .my-balance span:nth-child(1),
.modal-fuel .partner-balance span:nth-child(1) {
    width: 180px
}

.m-fuel-input {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 40px
}

.m-fuel-input input.st-in {
    display: block;
    height: 28px;
}

.fuel-go {
    background: var(--accent-color);
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    height: 28px;
    letter-spacing: -.11px;
    line-height: 28px;
    margin-left: 20px;
    text-align: center;
    text-transform: capitalize;
    width: 80px
}

body.dark .modal-fuel * {
    color: var(--text-color-dark)
}

body.dark .modal-fuel .fuel-go {
    color: #111
}

body.dark .modal-fuel .st-in {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark);
}

body.dark .modal-icon-q svg {
    fill: var(--text-color-dark)
}

body.dark .modal-title-q {
    color: var(--text-color-dark)
}

body.dark .modal-inner-mini .modal-title {
    color: var(--text-color-dark)
}

body.dark .modal > div .p-btn.extra {
    color: #000
}

.modal-device-title {
    color: #000;
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    height: 80px;
    line-height: 80px;
    padding-right: 35px;
    text-align: center;
    text-transform: uppercase
}

.modal-device-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 455px;
    padding: 0 0 30px 35px;
    position: relative;
    width: 538px
}

.modal-device-options {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 35px
}

.modal-device-btn {
    padding-right: 35px
}

.modal-device-btn .p-btn {
    float: right;
    width: 101px
}

.device-options-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: var(--hr-line-border);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 5px 0;
    width: 100%
}

body.dark .device-options-item {
    border-bottom: var(--hr-line-border-dark);
}

.device-options-item:last-child {
    border-bottom: none
}

.device-options-item > div {
    font-size: 13px;
    font-weight: 400;
    position: relative;
    width: 25%
}

body.dark .modal-device-info * {
    color: var(--text-color-dark)
}

.device-options-item input.st-in {
    width: 100%
}

body.dark .modal-device-info .st-in {
    background: var(--input-bg-dark);
    border: var(--input-border-dark);
    //-webkit-box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    //box-shadow: inset 0 -2px 18px #0a0a0b, inset 2.5px 2.5px 16px #000;
    color: var(--text-color-dark)
}


.content-bl {
    flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
    background: var(--content-bg);
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex: 1;
    height: 100%;
    margin-bottom: 120px;
    min-height: 200px;
    position: relative;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    border-top-left-radius: 55px;
    border-top-right-radius: 55px;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    border-collapse: separate;
    overflow: hidden;
    overflow: clip;
    contain: paint;
    margin-bottom: 49px;
}

body.dark .content-bl {
    //-webkit-box-shadow: 7px 15px 50px #000;
    background: var(--content-bg-dark);
    border: var(--content-border-dark);
    //box-shadow:  7px 15px 50px #000;
}

.content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 22px 25px;
    -webkit-overflow-scrolling: touch;
}


@media (max-height: 800px) {
    .content-bl {
        margin-bottom: 49px
    }
}

@media (min-width: 1600px) and (max-width: 1920px) {
    .content .table-tr > div {
        font-size: 0.76vw;
    }
}

@media (min-width: 1920px) {
    .content .table-tr > div {
        font-size: 14px;
    }
}



.guide-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    z-index: 999;
    padding: 49px;

}

.guide-overlay * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.guide--open .guide-overlay {
    -webkit-backdrop-filter: none !important;
    backdrop-filter: none !important;
    background-color: rgba(0, 0, 0, 0.5);
}

body.ReactModal__Body--open.guide--open .App {
    -webkit-transform: none;
    transform: none;
}

.guide-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    line-height: 28px;
    padding: 13px 15px;
}

.g-prev-btn,
.g-next-btn {
    width: 60px;
}

.g-prev-btn > div {
    font-weight: 400;
    cursor: pointer;
    color: var(--text-color);
    font-size: 14px;
    margin-left: 10px;
}

.g-next-btn > div {
    background: var(--accent-color);
    border-radius: 8px;
    width: 60px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    color: #FFF !important;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.11px;
    text-transform: capitalize;
    cursor: pointer;
    justify-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}


.g-dots {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.g-dot {
    margin: 0 4px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #D9D9D9;
}

.g-dot-active {
    background: var(--accent-color);
}

.guide-overlay .slick-current .title-slide {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    opacity: 1;
    padding: 0 20px;
}


.guide-title {
    padding: 20px 0 0 0;
}

.guide-titles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.active-title {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 700;
    justify-self: center;
    padding: 0 11px;
    white-space: nowrap;
}

.prev-title,
.next-title {
    font-size: 14px;
    font-weight: 700;
    opacity: .5;
    line-height: 22px;
    width: 50%;
}

.prev-title {
    text-align: right;
}
.next-title {
    padding-right: 20px;
    text-align: left;
}

.prev-title > span {
    background: -webkit-gradient(linear, right top, left top, from(#555), to(rgba(166, 167, 169, 0.00)));
    background: linear-gradient(270deg, #555 0%, rgba(166, 167, 169, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.next-title > span {
    background: -webkit-gradient(linear, left top, right top, from(#555), to(rgba(166, 167, 169, 0.00)));
    background: linear-gradient(90deg, #555 0%, rgba(166, 167, 169, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.prev-title > span,
.next-title > span {
    cursor: pointer;
    display: inline-block;
    -webkit-transition: 0.15s ease;
    transition: 0.15s ease;
    will-change: background;
    will-change: transform;
}

.prev-title > span:hover,
.next-title > span:hover {
    background-color: #555;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: 999;
}
/*

.prev-title > span:hover {
    transform-origin: right center;
}

.next-title > span:hover {
    transform-origin: left center;
}
*/

.modal-guide {
    width: 405px;
    height: 332px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.guide-overlay .modal {
    border-radius: 10px;
}

.guide-overlay .window_close {
    -webkit-transition: .3s;
    transition: .3s;
}

.next-title-hover {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
}


.guide--open .guide-overlay {
    background: none;
}

/*
.guide--open .guide-overlay  {
    background: none;
}
.content-bl {
    z-index: -34444443;
}*/

.guide--open [data-guide="active"] {
    z-index: 9999999;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
}

.guide--open [data-guide="active"] > * {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.guide--open .header::before {
    z-index: 9999990;
    content: '';
    position: fixed;
    width: 200vw;
    height: 100vh;
    top: 0;
    left: -100vw;
    background-color: rgba(0, 0, 0, 0.5);
}

.guide--open {
    overflow-x: hidden;
}

.guide--open .header [data-guide="active"]:not(.user)::after {
    z-index: 9999990;
    content: '';
    position: absolute;
    width: 25px;
    height: 35px;
    top: 100%;
    left: 50%;
    background-color: #fff;
    background: url(../shared/images/guide-arrow.svg) center center/contain no-repeat;
    -webkit-transform: translateY(15px) translateX(-50%);
    transform: translateY(15px) translateX(-50%);
    -webkit-animation: moveUpDown 1.2s infinite ease-in-out;
    animation: moveUpDown 1.2s infinite ease-in-out;
}


@-webkit-keyframes moveUpDown {
    0%, 100% {
        -webkit-transform: translateY(15px) translateX(-50%);
        transform: translateY(15px) translateX(-50%);
    }
    50% {
        -webkit-transform: translateY(45px) translateX(-50%);
        transform: translateY(45px) translateX(-50%);
    }
}


@keyframes moveUpDown {
    0%, 100% {
        -webkit-transform: translateY(15px) translateX(-50%);
        transform: translateY(15px) translateX(-50%);
    }
    50% {
        -webkit-transform: translateY(45px) translateX(-50%);
        transform: translateY(45px) translateX(-50%);
    }
}

.guide--open .modal-overlay:not(.guide-overlay) .modal-deposit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

.Toastify__toast {
    z-index: 99999 !important;
}

.guide--open .modal-overlay [data-guide="active"]::after {
    z-index: 9500;
    content: '';
    position: absolute;
    width: 25px;
    height: 35px;
    top: 50%;
    right: -15px;
    background-color: #fff;
    background: url(../shared/images/guide-arrow.svg) center center/contain no-repeat;
    -webkit-transform: translateX(100%) translateY(-50%) rotate(-90deg);
    transform: translateX(100%) translateY(-50%) rotate(-90deg);
    -webkit-animation: moveLeftRight 1.2s infinite ease-in-out;
    animation: moveLeftRight 1.2s infinite ease-in-out;
}


@-webkit-keyframes moveLeftRight {
    0%, 100% {
        -webkit-transform: translateX(80%) translateY(-50%) rotate(-90deg);
        transform: translateX(80%) translateY(-50%) rotate(-90deg);
    }
    50% {
        -webkit-transform: translateX(140%) translateY(-50%) rotate(-90deg);
        transform: translateX(140%) translateY(-50%) rotate(-90deg);
    }
}


@keyframes moveLeftRight {
    0%, 100% {
        -webkit-transform: translateX(80%) translateY(-50%) rotate(-90deg);
        transform: translateX(80%) translateY(-50%) rotate(-90deg);
    }
    50% {
        -webkit-transform: translateX(140%) translateY(-50%) rotate(-90deg);
        transform: translateX(140%) translateY(-50%) rotate(-90deg);
    }
}



.g-sl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.g-desc {
    padding-bottom: 1px;
}

.g-icon {
    padding-bottom: 15px;
}

.g-icon .p-btn {
    width: 85px;
    height: 85px;
    border-radius: 50px;
    margin: 0 auto;
    -webkit-transform: none !important;
    transform: none !important;
    cursor: auto;
}

.g-icon .p-btn.g-settings {
    width: 126px;
    height: 51px;
}


.g-icon .p-btn.g-t {
    width: 207px;
    height: 45px;
    font-weight: 700;
    font-size: 15px;
    color: #777;
}

.g-icon .p-btn.g-settings svg {
    width: 34px;
    height: 34px;
}

.g-icon svg {
    width: 50px;
    height: 50px;
}

.guide-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 20px 25px 0px 25px;
    font-size: 13px;
    position: relative;
}

.guide-content p svg {
    max-width: 30px;
    max-height: 15px;
}

.guide-content p svg:nth-child(2) {
    -webkit-transform: translateY(4px) translateX(2px) scale(3);
    transform: translateY(4px) translateX(2px) scale(3);
}

.g-img {
    min-width: 90px !important;
}

.g-img svg {
    height: 30px;
    width: 70px;
    -webkit-transform: scale(1.5) translateY(2px);
    transform: scale(1.5) translateY(2px);
}

.g-desc-item:nth-child(1) svg,
.g-desc-item:nth-child(2) svg {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
}

.g-desc-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


body.dark .g-next-btn > div {
    color: #000;;
}

body.dark .g-dot:not(.g-dot-active) {
    background: #444;
}

body.dark .g-desc {
    color: var(--text-color-dark);
}

body.dark .g-prev-btn > div {
    color: var(--text-color-dark);
}

body.dark .g-icon .p-btn.g-t {
    color: var(--text-color-dark);
}

body.dark .active-title {
    color: var(--text-color-dark);
}


body.dark .prev-title > span {
background: -webkit-gradient(linear, right top, left top, from(#eee), to(rgba(166, 167, 169, 0.00)));
background: linear-gradient(270deg, #eee 0%, rgba(166, 167, 169, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.dark .next-title > span {
    background: -webkit-gradient(linear, left top, right top, from(#eee), to(rgba(166, 167, 169, 0.00)));
    background: linear-gradient(90deg, #eee 0%, rgba(166, 167, 169, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.dark .prev-title > span:hover, body.dark .next-title > span:hover {
    background-color: #eee;
}



.g-img-sh {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

body.dark .g-img-sh {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));
}

body.dark .g-desc-item:not(:last-child) .g-img {
    filter: invert(1) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.65));
}

.inv-svg {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

body.dark .inv-svg {
    filter: invert(1) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.65))
}

.lock-locked {
    background: url(../shared/images/lock-locked.svg) center center/contain no-repeat;
}

.lock-unlocked {
    background: url(../shared/images/lock-unlocked-light.svg) center center/contain no-repeat;
}

body.dark .lock-unlocked {
    background: url(../shared/images/lock-unlocked-dark.svg) center center/contain no-repeat;
}