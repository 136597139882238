.label {
  cursor: pointer;
  text-align: center;
}

.light {
  color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loginPage {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.hidden {
  visibility: hidden;
}

.agentCodeInput {
  background: #f2f2f6;
  border: 1px solid #f7f7fa;
  border-radius: 23px;
  //-webkit-box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
  //box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  letter-spacing: -.11px;
  line-height: 25px;
  outline: none;
  padding: 0 10px;
}

.agentCodeInput::placeholder {
  color: #b1b1b1;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.agentCodeInput:focus::placeholder {
  color: transparent !important;
}

.darkInput {
  background: var(--input-bg-dark);
  border: var(--input-border-dark);
  color: var(--text-color-dark);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: none !important;
}

.loading::after {
  -webkit-animation: updating-animation 2s infinite linear;
  animation: updating-animation 2s infinite linear;
  background: url(../../shared/images/updating.svg) center center/contain no-repeat;
  content: '';
  display: block;
  height: 25px;
  width: 25px
}

@keyframes updating-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}